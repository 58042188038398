<template>
  <div class="main-page-wrapper">
    <Header  />
    <HeroLearning/>
    <CarouselLogos/>
    <ValueLearning/>
    <FeaturesLearning/>
    <CTACourse/>
    <Pricing id="pricing"/>
    <Contact id="contact"/>
    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/HeaderFour.vue";
import ValueLearning from "./ValueLearning.vue";
import CTACourse from "../common/CTA/Course.vue";
import FeaturesLearning from "./FeaturesLearning.vue";
import Footer from "../common/Footers/Footer.vue";
import Contact from "../common/Contact.vue";
import CarouselLogos from "../common/Logos.vue";
import Pricing from "../common/Pricing.vue"
import HeroLearning from "./HeroLearning.vue";




export default {
  name: "LearningNectios",
  components: {
    Header,
    ValueLearning,
    FeaturesLearning,
    CTACourse,
    Contact,
    Footer,
    CarouselLogos,
    Pricing,
    HeroLearning,

}

};
</script>