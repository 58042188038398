<template>
  <div class="fancy-feature-seven">
    <div class="container">
      <div class="title-style-two text-center mb-50 md-mb-70">
        <p>VALUE FOR YOUR OFFICE</p>
        <h2>Consolidate the <span>transfer function<img src="../../../assets/images/shape/line-shape-5.svg" alt=""></span>
        </h2>
      </div> <!-- /.title-style-two -->

      <div class="block-wrapper">

        <div v-for="item in feature_data" :key="item.id" class="block-style-nine event">
          <div class="row align-items-center">
            <div :class="`col-lg-7 col-md-9 m-auto ${item.order_1 ? item.order_1 : ''}`" 
            :data-aos="item.fade_1" data-aos-duration="1200">
              <div class="illustration-holder">
                <img :src="item.img" alt="">
              </div> <!-- /.illustration-holder -->
            </div>
            <div :class="`col-lg-5 ${item.order_2 ? item.order_2 : ''}`" 
            :data-aos="item.fade_2" data-aos-duration="1200">
              <div class="text-wrapper">
                <h6>{{item.sm_title}}</h6>
                <h3 class="title">{{item.title}}</h3>
                <p >{{item.subtitle}}</p>
              </div> <!-- /.text-wrapper -->
            </div>
          </div>
        </div> <!-- /.block-style-nine -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValueEvent',
  data () {
    return {
      feature_data : [
      
        {
          id:1,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/otris01.png`),
          sm_title:'SHARE',
          title:'📂 Create a database',
          subtitle:'Use our virtual repository to share your lines of research and the R+D projects your investigators are working on.',
        },
        {
          id:2,
          fade_1:'fade-right',
          fade_2:'fade-left',
          img:require(`../../../assets/images/assets/otris02.png`),
          sm_title:'CONNECT',
          title:'📱 Bring investigators and companies together',
          subtitle:'Create a perfect match for researchers and companies. Increase the success rate when identifying the needs of the market and establish research contracts to solve them.',
        },
        {
          id:3,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/otris03.png`),
          sm_title:'NETWORKING',
          title:'👩‍💼 Develop collaborative projects',
          subtitle:'The perfect platform to create and manage every aspect of a R+D project: from task managing, to indexable articles, meeting rooms and much more. '
        },
        {
          id:4,
          fade_1:'fade-right',
          fade_2:'fade-left',
          img:require(`@/assets/images/assets/otris04.png`),
          sm_title:'EFFICIENCY',
          title:'✔️ Save time and money',
          subtitle:'Use our interactive tools to advise your investigators and researchers on the preparation of research contracts, R&D project proposals and much more.'
        },
        
      ]
    }
  }
}
</script>
<style>


</style>