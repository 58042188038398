<template>
  <div class="partner-slider-two mt-50 mb-50 md-mt-20">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-10 col-md-10 m-auto">
          <p class="text-center text-lg" v-html="$t('logos.p')"></p>
        </div>
      </div>
      <div class="partnerSliderTwo">
        <carousel
          :items-to-show="5"
          :wrap-around="true"
          :autoplay="4000"
          :mouseDrag="true"
          :breakpoints="breakpoints"
        >
          <slide v-for="logo in logoSliderData" :key="logo.id">
            <div class="item">
              <div
                class="img-meta d-flex align-items-center justify-content-center"
              >
                <img :src="logo.img" alt="" />
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
  <!-- /.partner-slider-two -->
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "CarouselLogos",
  data() {
    return {
      logoSliderData: [
        {
          id: 1,
          img: require(`@/assets/images/logo/barcelona-activa.png`),
        },
        {
          id: 2,
          img: require(`@/assets/images/logo/comercio-medellin.png`),
        },
        {
          id: 3,
          img: require(`@/assets/images/logo/factorial.png`),
        },
        {
          id: 4,
          img: require(`@/assets/images/logo/iese.png`),
        },
        {
          id: 5,
          img: require(`@/assets/images/logo/universitat-girona.png`),
        },
        {
          id: 6,
          img: require(`@/assets/images/logo/tendios.png`),
        },
        {
          id: 7,
          img: require(`@/assets/images/logo/global-startup-cities.png`),
        },
      ],
      breakpoints: {
        0: {
          itemsToShow: 2,
          snapAlign: "left",
        },
        576: {
          itemsToShow: 3,
          snapAlign: "left",
        },
        768: {
          itemsToShow: 4,
          snapAlign: "left",
        },
        992: {
          itemsToShow: 5,
          snapAlign: "left",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
  },
  methods: {
    onSubmit() {
      console.log("Submitted");
    },
  },
};
</script>
