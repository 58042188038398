<template>
  <div
    :class="`doc-container full-width ${
      doc_banner ? 'mt-70 sm-m0' : 'top-border'
    }`"
    style="margin-right: 50px; margin-left: 50px"
  >
    <div class="clearfix">
      <div class="row flex-xl-nowrap g-0">
        <!-- ****************************** DOC SIDEBAR ********************************* -->
        <div class="col-md-3 col-xl2 doc-sidebar">
          <div class="clearfix">
            <button
              class="btn btn-link d-md-none collapsed"
              type="button"
              data-bs-target="#doc-sidebar-nav"
              data-bs-toggle="collapse"
              aria-controls="doc-sidebar-nav"
              aria-expanded="false"
              aria-label="Toggle docs navigation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="30"
                height="30"
                focusable="false"
              >
                <title>Menu</title>
                <path
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  d="M4 7h22M4 15h22M4 23h22"
                ></path>
              </svg>
            </button>
          </div>
          <nav class="doc-links collapse clearfix nav-fix" id="doc-sidebar-nav">
            <!--
                     <div class="search-form">
                       <form @submit.prevent="onSubmit">
                          <input type="text" placeholder="Search here..">
                          <button><img src="../../assets/images/icon/59.svg" alt=""></button>
                       </form>
                     </div>
                     
                     -->
            <ul class="list-item">
              <li class="dropdown-holder">
                <h4 data-bs-toggle="collapse" data-bs-target="#quick-start">
                  {{ $t("features.add-ons") }}
                </h4>
                <ul
                  class="sub-menu show"
                  id="quick-start"
                  v-html="$t('features.list')"
                ></ul>
              </li>
            </ul>
          </nav>
          <!-- /.doc-links -->
        </div>
        <!-- /.doc-sidebar -->
        <!-- ****************************** DOC MAIN BODY ********************************* -->
        <main
          class="col-md-9 col-xl-8 doc-main-body"
          v-html="$t('features.content')"
        ></main>
        <!-- /.doc-main-body 
               <nav class="d-none d-xl-block col-xl-2 doc-sideNav">
                  <div class="wrapper">
                     <nav class="main-side-nav">
                        <a href="#installing" class="nav-link">Installing Deski</a>
                        <a href="#verifying" class="nav-link">Verifying Installation</a>
                        <a href="#manual-installation" class="nav-link">Manual Installation</a>
                        <a href="#running-example" class="nav-link">Running example website</a>
                        <a href="#launching-server" class="nav-link">Launching the server</a>
                        <a href="#loading-dialog" class="nav-link">Loading dialog</a>
                     </nav>
                  </div>
               </nav>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FeaturesArea",
  props: { doc_banner: Boolean },
  methods: {
    onSubmit() {
      console.log("Submitted");
    },
  },
};
</script>
