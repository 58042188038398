<template>
  <div class="client-feedback-slider-one pt-50 pb-50 md-pb-80">
    <div class="shapes-holder">
      <img src="../../../assets/images/shape/39.svg" alt="">
      <img src="../../../assets/images/shape/64.svg" alt="" class="shapes shape-one">
      <div class="title-style-two">
        <h2>You don't have <br> to take our <br> word for it</h2>
      </div>
    </div> <!-- /.shapes-holder -->
    <div class="container">
      <div class="row">
        <div class="col-lg-6 ms-auto">
          <div class="feedback-meta">
            <img src="../../../assets/images/icon/30.svg" alt="" class="icon">
      
              <swiper class="clientSliderOne" ref="mySwiper" :modules="modules" :loop="true"
                :navigation="{ nextEl: '.prev_c', prevEl: '.next_c' }">
                <swiper-slide v-for="testimonial in testimonialData" :key="testimonial.id">
                  <div class="item">
                    <p >{{testimonial.desc}}</p>
                    <div class="d-lg-flex align-items-center">
                      <img :src="testimonial.img" alt="" class="c_img">
                      <div class="info">
                        <strong>{{testimonial.name}}</strong> <span>{{testimonial.title}}</span>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <ul class="d-flex slider-arrow mt-40">
                  <li class="prev_c"><i class="flaticon-right-arrow"></i></li>
                  <li class="next_c"><i class="flaticon-right-arrow"></i></li>
                </ul>
              </swiper>

          </div> <!-- /.feedback-meta -->
        </div>
      </div>
    </div> <!-- /.container -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";

export default {
  name: 'ClientFeedback',
  data() {
    return {
      testimonialData: [
        {
          id: 1,
          name: "Lydia",
          title: "Cluster Manager, Spain",
          img: require(`../../../assets/images/testimonials/5.png`),
          desc: "We created a private area for our associates with Nectios platform, and we couldn’t be happier. You can choose the features you want to use with full flexibility and it has a really easy to use edit mode.",
        },
        {
          id: 2,
          name: "Marcos",
          title: "Head of People, Spain",
          img: require(`../../../assets/images/testimonials/6.png`),
          desc: "We chose Nectios platform to build our intranet because of its interactive tools, which have helped us to increase our engagement by 15%!",
        },      
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>