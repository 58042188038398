<template>
  <div class="fancy-feature-four mt-20">
    <div class="bg-wrapper">
      <img src="../../../assets/images/shape/18.svg" alt="" class="shapes shape-right">
      <img src="../../../assets/images/shape/19.svg" alt="" class="shapes shape-left">
      <div class="container">
        <div class="title-style-two text-center mb-50 md-mb-50">
          <div class="row">
            <div class="col-xl-8 col-lg-9 col-md-10 m-auto">
              <p>Business community users</p>
              <h2>Who would benefit the most</h2>
            </div>
          </div>
        </div>
        <div class="inner-content">
          <img src="../../../assets/images/shape/65.svg" alt="" class="shapes shape-one">
          <img src="../../../assets/images/shape/64.svg" alt="" class="shapes shape-two">
          <div class="row justify-content-center">
            <div v-for="item in fancyFeatureData" :key="item.id" class="col-lg-4 col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200" :data-aos-delay="item.delay">
              <div class="block-style-five flex column justify-content-center">
                <div class="flex justify-content-center"><img :src="item.icon" alt=""></div>
                <h6 class="title"><span>{{item.title}}</span></h6>
              </div> <!-- /.block-style-five -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
export default {
  name: 'FancyFeature',
  data () {
    return {
      fancyFeatureData:[
      {
          id:1,
          icon:require(`../../../assets/images/stories/chamber.png`),
          title:'Chambers of Commerce',
        },
        {
          id:2,
          icon:require(`../../../assets/images/stories/cluster.png`),
          title:' Clusters',
          delay:'100'
        },
        {
          id:3,
          icon:require(`../../../assets/images/stories/college.png`),
          title:'Professional Colleges',
          delay:'200'
        },
      ]
    }
  }
}
</script>