<template>
  <Learning/>
</template>

<script>
import Learning from '../components/learning';

export default {
  name:'LearningNectios',
  components:{
   Learning
  },

 
}
</script>