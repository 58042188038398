<template>
  <div class="fancy-feature-seven">
    <div class="container">
      <div class="title-style-two text-center mb-50 md-mb-70">
        <p>ADDED VALUE</p>
        <h2>Discover what we bring to your <span>your business<img src="../../../assets/images/shape/line-shape-5.svg" alt=""></span>
        </h2>
      </div> <!-- /.title-style-two -->

      <div class="block-wrapper">

        <div v-for="item in feature_data" :key="item.id" class="block-style-nine event">
          <div class="row align-items-center">
            <div :class="`col-lg-7 col-md-9 m-auto ${item.order_1 ? item.order_1 : ''}`" 
            :data-aos="item.fade_1" data-aos-duration="1200">
              <div class="illustration-holder">
                <img :src="item.img" alt="">
              </div> <!-- /.illustration-holder -->
            </div>
            <div :class="`col-lg-5 ${item.order_2 ? item.order_2 : ''}`" 
            :data-aos="item.fade_2" data-aos-duration="1200">
              <div class="text-wrapper">
                <h6>{{item.sm_title}}</h6>
                <h3 class="title">{{item.title}}</h3>
                <p >{{item.subtitle}}</p>
              </div> <!-- /.text-wrapper -->
            </div>
          </div>
        </div> <!-- /.block-style-nine -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValueEvent',
  data () {
    return {
      feature_data : [
      
        {
          id:1,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/summits01.png`),
          sm_title:'PARTICIPATION',
          title:'👩‍💼 Host webinars like a pro',
          subtitle:'Create and host webinars with ease, while also benefiting from features such as interactive files, live Q&A sessions, and detailed analytics to track your webinars success.',
        },
        {
          id:2,
          fade_1:'fade-right',
          fade_2:'fade-left',
          img:require(`../../../assets/images/assets/summits02.png`),
          sm_title:'ROI',
          title:'🚀 Increase the attendance rate by 15%',
          subtitle:'Our innovative platform offers a range of powerful tools and features that allow organizers to create and manage engaging events that capture the attention of their target audience.',
        },
        {
          id:3,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/summits03.png`),
          sm_title:'ANALYTICS',
          title:'🔎 Track the activity',
          subtitle:'Extract the metrics of your event and learn which aspects work better to promote them in your next summit or conference.'
        },
            
      ]
    }
  }
}
</script>
<style>


</style>