<template>
  <div class="main-page-wrapper">
    <Header  />
    <Hero/>
    <Logos/>
    <Value/>
    <Target/>
    <CTA/>
    <Testimonials/>
    <Pricing id="pricing"/>
    <Contact id="contact"/>
    <Footer />
  </div>
</template>

<script>
import Header from "./../../common/Headers/HeaderFour.vue";
import Hero from "./Hero.vue";
import CTA from "./../../common/CTA/business.vue";
import Testimonials from "./Testimonials.vue";
import Value from "./Value.vue";
import Target from "./Target.vue";
import Footer from "./../../common/Footers/Footer.vue";
import Logos from "./../../common/Logos.vue";
import Contact from "./../../common/Contact.vue";
import Pricing from "./../../common/Pricing.vue";



export default {
  name: "UseCaseBusinessCommunity",
  components: {
    Header,
    Hero,
    Value,
    Testimonials,
    Target,
    CTA,
    Footer,
    Logos,
    Contact,
    Pricing,
},

};
</script>