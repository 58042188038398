<template>
  <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
         <div class="mb-50"><img src="@/assets/images/logo/kitdigital.jpg" alt="kit digital"></div>
        <div class="col-xl-10 m-auto">
          <h1 class="h1">KIT DIGITAL</h1>
        </div>
        <div class="col-xl-9 m-auto">
          <p class="font-rubik">Consigue hasta 12.000€ para digitalizar tu negocio. Si eres una empresa o un autónomo te ayudamos a impulsar tu marca mediante la subvención del Programa Kit Digital.</p>
          <a href="#contact"  class="secondary-button btn-lg mt-50 md-mt-30">Contacta</a>
        </div>
      </div>
      <div class="wrapper mt-50">
           <!-- ¡Servicios-->
           <h3 class="uppercases" align="center">CATEGORÍAS</h3>
           <div class="row justify-content-center">
              <div class="col-lg-3 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Sitio Web y Posicionamiento</h4>
                    <a  style="transform:rotate(90deg) scale(0.8)"  href="#sitioweb" ><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-3 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Gestión de Redes Sociales</h4>
                    <a  style="transform:rotate(90deg) scale(0.8)" href="#redessociales" ><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-3 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                  <h4>Gestión de Clientes</h4>
                    <a  style="transform:rotate(90deg) scale(0.8)" href="#crm" ><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-3 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                  <h4>Servicios de Oficina Virtual</h4>
                    <a  style="transform:rotate(90deg) scale(0.8)" href="#oficina" ><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
           </div>
        </div>
        <!-- Servicios!-->
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
    
  </div>
  
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>