<template>
  <div class="clearfix">
    <Header />
    <HeroFeatures />
    <FeaturesArea :doc_banner="true" />
    <Pricing id="pricing" />
    <Contact id="contact" />
    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/HeaderFour.vue";
import HeroFeatures from "./HeroFeatures.vue";
import FeaturesArea from "./FeaturesArea.vue";
import Footer from "../common/Footers/Footer.vue";
import Pricing from "../common/Pricing.vue";
import Contact from "../common/Contact.vue";

export default {
  name: "FeaturesNectios",
  components: { Header, HeroFeatures, FeaturesArea, Footer, Pricing, Contact },
};
</script>
