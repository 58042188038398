<template>
  <div class="fancy-feature-seventeen" id="product" >
    <div class="bg-wrapper" style="background:none">
      <div class="container">
        <div class="title-style-two text-center mb-100 md-mb-70">
        <p>FEATURES</p>
        <h2>
          Benefit from our in-house <span>apps & integrations<img src="../../../assets/images/shape/line-shape-5.svg" alt=""></span>
        </h2>
      </div> <!-- /.title-style-two -->
        <div class="row">
          <div
            v-for="item in featureData"
            :key="item.id"
            class="col-lg-3 col-sm-6 pt-50"
            data-aos="fade-up"
            data-aos-duration="1200"
            :data-aos-delay="item.delay"
          >
            <div class="block-meta">
              <div class="icon d-flex align-items-end">
                <img :src="item.icon" alt="" />
              </div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.desc }}</p>
            </div>
            <!-- /.block-meta -->
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: "FeaturesLearning",
  data() {
    return {
      featureData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/solution1.svg`),
          title: "Ticketing",
          desc: "Create and sell your tickets and manage them from the back-office.",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/solution6.svg`),
          title: "Discounts",
          desc: "Create discount codes for your early-birds and V.I.P audience.",
          delay: "100",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/solution5.svg`),
          title: "Live-streaming",
          desc: "Frame embedded in your event layout for broadcasting your event",
          delay: "200",
        },
        {
          id: 4,
          icon: require(`@/assets/images/icon/solution4.svg`),
          title: "Agenda",
          desc: "Share the schedule of your event and link it to online rooms..",
          delay: "300",
        },
        {
          id: 5,
          icon: require(`@/assets/images/icon/solution2.svg`),
          title: "Surveys",
          desc: "Collect feedback from the participants of your summit or conference.",
        },
        {
          id: 6,
          icon: require(`@/assets/images/icon/solution3.svg`),
          title: "Business cards",
          desc: "Create contact information cards of your speakers and promote networking.",
          delay: "100",
        },
        {
          id: 7,
          icon: require(`@/assets/images/icon/solution7.svg`),
          title: "Sections",
          desc: "Create different spaces in order to categorize the information of your event.",
          delay: "200",
        },
        {
          id: 8,
          icon: require(`@/assets/images/icon/solution8.svg`),
          title: "Questions",
          desc: "Interactive feature where participants can ask and answer questions.",
          delay: "300",
        },
       
      ],
    };
  },
};
</script>
