<template>
  <div class="main-page-wrapper">
    <Header  />
    <HeroEvent/>
    <Logos/>
    <ValueEvent/>
    <UseCasesEvent/>
    <StoriesEvent/>
    <CTAEvent/>
    <Pricing id="pricing"/>
    <Contact id="contact"/>
    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/HeaderFour.vue";
import HeroEvent from "./HeroEvent.vue"
import ValueEvent from "./ValueEvent.vue";
import CTAEvent from "../common/CTA/Event.vue";
import UseCasesEvent from "./UseCasesEvent.vue";
import StoriesEvent from "./StoriesEvent.vue";
import Footer from "../common/Footers/Footer.vue";
import Logos from "../common/Logos.vue";
import Contact from "../common/Contact.vue";
import Pricing from "../common/Pricing.vue";



export default {
  name: "EventsNectios",
  components: {
    Header,
    HeroEvent,
    ValueEvent,
    UseCasesEvent,
    CTAEvent,
    StoriesEvent,
    Footer,
    Logos,
    Contact,
    Pricing,
},

};
</script>