<template>
  <div class="hero-banner-eleven lg-container mb-110 md-mb-50">
     <div class="container">
        <div class="row">
           <div class="col-xl-7 col-lg-6" style="padding-left:5%">
              <div class="hero-text-wrapper">
               <p>RESEARCH RESULTS TRANSFER OFFICE</p>
                 <h1>
                  Share, advise and 
                  <span>connect
                     <img
                        src="../../../assets/images/shape/line-shape-12.svg"
                        alt=""
                        class="cs-screen"
                        style="z-index:-1"
                        />
                     </span>
                 </h1>
                 <p class="hero-sub-heading">Create a custom space to establish connection ties between the university and the companies</p>
                 <button class="primary-button" style="margin-left: 0 !important;"> <a target="_blank" href="https://calendly.com/xavier-creus/30min">Request Demo</a> </button>

                 <a target="_blank" class="term-text" href="https://www.capterra.es/software/1030228/nectios">
                 <img  src="../../../assets/images/assets/Capterra.png" alt="Capterra">
                 </a>
              </div>
              <!-- /.hero-text-wrapper -->
           </div>
           <!-- End .col -->
           <div class="illustration-container">
              <img src="../../../assets/images/assets/herootris.png" alt="Nectios-transfer-office" />
           </div>
        </div>
        <!-- End .row -->
     </div>
     <!-- End .container -->
     <!-- /.partner-slider-two -->
  </div>
</template>
<script>
  export default {
    name: "HeroEvent",
   
   
    methods: {
      onSubmit() {
        console.log("Submitted");
      },
    },
  };
</script>