<template>
  <div class="fancy-feature-seventeen" id="product">
    <div class="bg-wrapper" style="background: none">
      <div class="container">
        <div class="title-style-two text-center mb-100 md-mb-70">
          <p>{{ $t("network-cases.title") }}</p>
          <h2>
            <span
              >{{ $t("network-cases.h2") }}
              <img src="../../assets/images/shape/line-shape-4.svg" alt=""
            /></span>
          </h2>
        </div>
        <!-- /.title-style-two -->
        <div class="row">
          <div
            v-for="item in featureData"
            :key="item.id"
            class="col-lg-4 col-sm-6 pt-50"
            data-aos="fade-up"
            data-aos-duration="1200"
            :data-aos-delay="item.delay"
          >
            <div class="block-meta">
              <div class="icon d-flex align-items-end">
                <img :src="item.icon" alt="" />
              </div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.desc }}</p>
            </div>
            <!-- /.block-meta -->
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: "UseCasesNetwork",
  computed: {
    featureData() {
      return [
        {
          id: 1,
          icon: require(`@/assets/images/icon/solution3.svg`),
          title: this.$t("network-cases.s1_title"),
          desc: this.$t("network-cases.s1_desc"),
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/solution7.svg`),
          title: this.$t("network-cases.s2_title"),
          desc: this.$t("network-cases.s2_desc"),
          delay: "100",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/solution1.svg`),
          title: this.$t("network-cases.s3_title"),
          desc: this.$t("network-cases.s3_desc"),
          delay: "200",
        },
      ];
    },
  },
};
</script>
