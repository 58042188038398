<template>
  <div class="fancy-feature-seven">
    <div class="container">
      <div class="title-style-two text-center mb-50 md-mb-70">
        <p>{{ $t("home-steps.title") }}</p>
        <h2>
          {{ $t("home-steps.h2") }}
          <span
            >Nectios<img
              src="../../assets/images/shape/line-shape-5.svg"
              alt=""
          /></span>
        </h2>
      </div>
      <!-- /.title-style-two -->
      <div class="block-wrapper">
        <div
          v-for="item in feature_data"
          :key="item.id"
          class="block-style-nine steps"
        >
          <div class="row align-items-center">
            <div
              :class="`col-lg-7 col-md-9 m-auto ${
                item.order_1 ? item.order_1 : ''
              }`"
              :data-aos="item.fade_1"
              data-aos-duration="1200"
            >
              <div class="illustration-holder">
                <img :src="item.img" alt="" />
              </div>
              <!-- /.illustration-holder -->
            </div>
            <div
              :class="`col-lg-5 ${item.order_2 ? item.order_2 : ''}`"
              :data-aos="item.fade_2"
              data-aos-duration="1200"
            >
              <div class="text-wrapper">
                <h6>{{ item.sm_title }}</h6>
                <h3 class="title">{{ item.title }}</h3>
                <p>{{ item.subtitle }}</p>
              </div>
              <!-- /.text-wrapper -->
            </div>
          </div>
        </div>
        <!-- /.block-style-nine -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyFeature",
  computed: {
    feature_data() {
      return [
        {
          id: 1,
          fade_1: "fade-left",
          fade_2: "fade-right",
          order_1: "order-lg-last",
          order_2: "order-lg-first",
          img: require(`@/assets/images/assets/step01.png`),
          sm_title: this.$t("home-steps.s1_sm"),
          title: this.$t("home-steps.s1_title"),
          subtitle: this.$t("home-steps.s1_sub"),
        },
        {
          id: 2,
          fade_1: "fade-right",
          fade_2: "fade-left",
          img: require(`@/assets/images/assets/step02.png`),
          sm_title: this.$t("home-steps.s2_sm"),
          title: this.$t("home-steps.s2_title"),
          subtitle: this.$t("home-steps.s2_sub"),
        },
        {
          id: 3,
          fade_1: "fade-left",
          fade_2: "fade-right",
          order_1: "order-lg-last",
          order_2: "order-lg-first",
          img: require(`@/assets/images/assets/step03.png`),
          sm_title: this.$t("home-steps.s3_sm"),
          title: this.$t("home-steps.s3_title"),
          subtitle: this.$t("home-steps.s3_sub"),
        },
      ];
    },
  },
};
</script>
<style>
.block-style-nine.steps:nth-child(3):before {
  display: none;
}
</style>
