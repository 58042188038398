<template>
  <div class="fancy-text-block-six mt-10 mb-200">
    <div class="container">
      <div class="row">
        <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
          <div class="title-style-two mb-35">
            <p>{{ $t("home-value.title") }}</p>
            <h2>
              {{ $t("home-value.h2_1") }} <br />
              {{ $t("home-value.h2_2") }}
              <span>
                {{ $t("home-value.h2_3") }}
                <img src="../../assets/images/shape/line-shape-3.svg" alt=""
              /></span>
            </h2>
          </div>

          <!-- Accordion Style Two -->
          <div id="accordionExample" class="accordion-style-two pe-5">
            <div v-for="item in faqData" :key="item.id" class="accordion-item">
              <div class="accordion-header" :id="item.headingNum">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#' + item.collapseNum"
                    :aria-expanded="item.expanded ? 'true' : 'false'"
                    :aria-controls="item.collapseNum"
                  >
                    {{ item.btnText }}
                  </button>
                </h5>
              </div>
              <div
                :id="item.collapseNum"
                :class="item.collapseClass"
                :aria-labelledby="item.headingNum"
                data-bs-parent="#accordionExample"
                style=""
              >
                <div class="accordion-body">
                  <p>{{ item.desc }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="img-meta-container"
      data-aos="fade-left"
      data-aos-duration="1200"
      data-aos-delay="100"
    >
      <img src="../../assets/images/assets/review.png" alt="" />
      <img
        src="../../assets/images/shape/22.svg"
        alt=""
        class="shapes shape-one"
      />
      <img
        src="../../assets/images/shape/23.svg"
        alt=""
        class="shapes shape-two"
      />
      <img
        src="../../assets/images/shape/24.svg"
        alt=""
        class="shapes shape-three"
      />
      <img
        src="../../assets/images/shape/25.svg"
        alt=""
        class="shapes shape-four"
      />
      <img
        src="../../assets/images/shape/26.svg"
        alt=""
        class="shapes shape-five"
      />
      <img
        src="../../assets/images/shape/27.svg"
        alt=""
        class="shapes shape-six"
      />
      <img
        src="../../assets/images/shape/28.svg"
        alt=""
        class="shapes shape-seven"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
  computed: {
    faqData() {
      return [
        {
          id: 1,
          headingNum: "headingOne",
          collapseNum: "collapseOne",
          btnText: this.$t("home-value.s1_title"),
          desc: this.$t("home-value.s1_desc"),
          collapseClass: "accordion-collapse collapse show",
          expanded: true,
        },
        {
          id: 2,
          headingNum: "headingTwo",
          collapseNum: "collapseTwo",
          btnText: this.$t("home-value.s2_title"),
          desc: this.$t("home-value.s2_desc"),
          collapseClass: "accordion-collapse collapse",
        },
        {
          id: 3,
          headingNum: "headingThree",
          collapseNum: "collapseThree",
          btnText: this.$t("home-value.s3_title"),
          desc: this.$t("home-value.s3_desc"),
          collapseClass: "accordion-collapse collapse",
        },
      ];
    },
  },
};
</script>
