<template>
  <div class="fancy-feature-seven valuevent">
    <div class="container">
      <div class="title-style-two text-center mb-50 md-mb-70">
        <p>Value for your entity</p>
        <h2>Unify all your tools in a single platform</h2>
      </div> <!-- /.title-style-two -->
      <div class="block-wrapper">
        <div v-for="item in feature_data" :key="item.id" class="block-style-nine event">
          <div class="row align-items-center">
            <div :class="`col-lg-7 col-md-9 m-auto ${item.order_1 ? item.order_1 : ''}`" 
            :data-aos="item.fade_1" data-aos-duration="1200">
              <div class="illustration-holder">
                <img :src="item.img" alt="">
              </div> <!-- /.illustration-holder -->
            </div>
            <div :class="`col-lg-5 ${item.order_2 ? item.order_2 : ''}`" 
            :data-aos="item.fade_2" data-aos-duration="1200">
              <div class="text-wrapper">
                <h6>{{item.sm_title}}</h6>
                <h3 class="title">{{item.title}}</h3>
                <p >{{item.subtitle}}</p>
              </div> <!-- /.text-wrapper -->
            </div>
          </div>
        </div> <!-- /.block-style-nine -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValueEvent',
  data () {
    return {
      feature_data : [
      
        {
          id:1,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/business01.png`),
          sm_title:'SYNERGY',
          title:'✔️ Promote connections among your members',
          subtitle:'Build a business directory where entities and agents can find each other easily, thanks to filters and classifiers.',
        },
        {
          id:2,
          fade_1:'fade-right',
          fade_2:'fade-left',
          img:require(`../../../assets/images/assets/business02.png`),
          sm_title:'NETWORK',
          title:'🤝 Develop networking activities',
          subtitle:'Increase the participation for your workshops, webinars and collaborative projects to develop innovative solutions.',
        },
        {
          id:3,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/business03.png`),
          sm_title:'MONETIZE',
          title:'📈 Create a marketplace for your services',
          subtitle:'Create the perfect showcase for your products, solutions and services to get the most out of the platform.'
        },
        {
          id:4,
          fade_1:'fade-right',
          fade_2:'fade-left',
          img:require(`../../../assets/images/assets/business04.png`),
          sm_title:'CRM',
          title:'👩‍💻 Manage all your needs from a single platform',
          subtitle:'Unify all the tools you use on a daily basis: send newsletters, control your payments, tracking your associates activity and much more!'
        },
        
      ]
    }
  }
}
</script>
<style>


</style>