<template>
  <div class="client-feedback-slider-one pt-50 pb-50 md-pb-80">
    <div class="shapes-holder">
      <img src="../../../assets/images/shape/39.svg" alt="">
      <img src="../../../assets/images/shape/64.svg" alt="" class="shapes shape-one">
      <!--<img src="../../assets/images/media/img_21.png" alt="" class="cp-img-one">
      <img src="../../assets/images/media/img_22.png" alt="" class="cp-img-two">
      <img src="../../assets/images/media/img_23.png" alt="" class="cp-img-three">
      <img src="../../assets/images/media/img_24.png" alt="" class="cp-img-four"> -->
      <div class="title-style-two">
        <h2>You don't have <br> to take our <br> word for it</h2>
      </div>
    </div> <!-- /.shapes-holder -->
    <div class="container">
      <div class="row">
        <div class="col-lg-6 ms-auto">
          <div class="feedback-meta">
            <img src="../../../assets/images/icon/30.svg" alt="" class="icon">
      
              <swiper class="clientSliderOne" ref="mySwiper" :modules="modules" :loop="true"
                :navigation="{ nextEl: '.prev_c', prevEl: '.next_c' }">
                <swiper-slide v-for="testimonial in testimonialData" :key="testimonial.id">
                  <div class="item">
                    <p >{{testimonial.desc}}</p>
                    <div class="d-lg-flex align-items-center">
                      <img :src="testimonial.img" alt="" class="c_img">
                      <div class="info">
                        <strong>{{testimonial.name}}</strong> <span>{{testimonial.title}}</span>
                      </div>
                    </div>
                  </div>
                </swiper-slide>

                <ul class="d-flex slider-arrow mt-40">
                  <li class="prev_c"><i class="flaticon-right-arrow"></i></li>
                  <li class="next_c"><i class="flaticon-right-arrow"></i></li>
                </ul>
              </swiper>

          </div> <!-- /.feedback-meta -->
        </div>
      </div>
    </div> <!-- /.container -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";

export default {
  name: 'ClientFeedback',
  data() {
    return {
      testimonialData: [
        {
          id: 1,
          name: "Joan",
          title: "Industrial Promotion Technician, Spain",
          img: require(`../../../assets/images/testimonials/1.jpg`),
          desc: "Friendly and easy to manage interface. Our members are delighted with the community and positive feedback of the city Council activities has increased by 20%!",
        },
        {
          id: 2,
          name: "Nuria",
          title: "Deputy Mayor Assistant, Spain",
          img: require(`../../../assets/images/testimonials/2.jpg`),
          desc: "The custom fields and classifiers of the platform are game changers! We built a business directory with all the filters we wanted, and it’s working amazingly.",
        },
        {
          id: 3,
          name: "Daniela",
          title: "Head of Marketing, Colombia",
          img: require(`../../../assets/images/testimonials/3.jpg`),
          desc: "The platform is perfect for our need to celebrate multiple events during the year. It allows us to record the sessions and share it in the media repository, so we don’t lose any content.",
        },
        {
          id: 4,
          name: "Pedro",
          title: "Acquisition Specialist, Spain",
          img: require(`../../../assets/images/testimonials/4.jpg`),
          desc: "Since we created the community we have multiplied our visibility! Now we have reached most of the companies in our area!",
        },
        
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>