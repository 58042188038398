<template>
  <div class="main-page-wrapper">
    <Header  />
    <Hero/>
    <Logos/>
    <Value/>
    <Features/>
    <CTAB2B/>
    <Pricing id="pricing"/>
    <Contact id="contact"/>
    <Footer />
  </div>
</template>

<script>
import Header from "./../../common/Headers/HeaderFour.vue";
import Hero from "./Hero.vue";
import CTAB2B from "./../../common/CTA/B2B.vue";
import Value from "./Value.vue";
import Footer from "./../../common/Footers/Footer.vue";
import Logos from "./../../common/Logos.vue";
import Contact from "./../../common/Contact.vue";
import Pricing from "./../../common/Pricing.vue";
import Features from "./Features.vue";



export default {
  name: "UseCaseSummits",
  components: {
    Header,
    Hero,
    Value,
    CTAB2B,
    Footer,
    Logos,
    Contact,
    Pricing,
    Features
},

};
</script>