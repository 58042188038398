<template>
  <!--PresenciaOnline-->
  <div class="fancy-text-block-five pt-100 pb-100 md-pt-100 md-pb-100 bg-color-blue">
     <div class="container">
        <div class="row">
           <div class="col-lg-10">
              <div class="title-style-one mb-50 md-mb-30">
                 <h2 id="sitioweb">01. Sitio Web y Presencia en Internet</h2>
                 <p>Desde 590€</p>
                 <p><b>Objetivo:</b> Expansión de la presencia en internet de la pyme mediante la creación de una página web y/o la prestación de servicios que proporcionen posicionamiento básico en internet.</p>
                 <div class="pt-20">
                    <b>IMPORTE DE LA AYUDA</b>
                    <div style="display:flex;flex-wrap: wrap;gap:20px;">
                       <ul>
                          <li> <u>SEGMENTO III</u> </li>
                          <li>2000€</li>
                          <li>0 - 3 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO II</u> </li>
                          <li>2000€</li>
                          <li>3 - 9 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO I</u> </li>
                          <li>2000€</li>
                          <li>10 - 50 Empleados</li>
                       </ul>
                    </div>
                 </div>
              </div>
              <!-- /.title-style-one -->
           </div>
        </div>
        <div class="wrapper mt-10">
           <!-- ¡Servicios-->
           <h3 class="uppercases" align="center">DISEÑO WEB</h3>
           <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Hosting</h4>
                    <p>Alojamiento de la página web desarrollada durante un plazo mínimo de doce meses.</p>
                    <a target="_blank" href="https://www.elonial.com/diseno-web"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Accesibilidad</h4>
                    <p>El diseño debe cumplir con criterios de conformidad de nivel AA de las Pautas WCAG-2.1.</p>
                    <a target="_blank" href="https://www.elonial.com/diseno-web"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Web responsive</h4>
                    <p>Las páginas web diseñadas deben adaptarse para ser funcionales en todo tipo de dispositivos.</p>
                    <a target="_blank" href="https://www.elonial.com/diseno-web"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Dominio</h4>
                    <p>Alta de nuevo dominio para la pyme beneficiaria durante un plazo mínimo de doce meses. La titularidad del dominio será en su totalidad de la pyme.</p>
                    <a target="_blank" href="https://www.elonial.com/diseno-web"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Autogestionable</h4>
                    <p>Se deberá proveer una plataforma de gestión de contenidos para el beneficiario, de manera que sea autónomo a la hora de modificar el contenido de sus páginas web, sin la necesidad de recurrir al soporte de la empresa proveedora.</p>
                    <a target="_blank" href="https://www.elonial.com/diseno-web"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Diseño de la página web</h4>
                    <p>Estructura web con un mínimo de 3 páginas o apartados. Se consideran páginas o apartados de una web, elementos tales como: página de inicio , presentación de la empresa, formulario de contacto, descripción de productos, datos de contacto, mapa del sitio , etc.</p>
                    <a target="_blank" href="https://www.elonial.com/diseno-web"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
           </div>
        </div>
        <!-- Servicios!-->
        <div class="wrapper mt-10">
           <!-- ¡Servicios-->
           <h3 class="uppercases" align="center">POSICIONAMIENTO SEO</h3>
           <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Posicionamiento básico en internet</h4>
                    <p >Posicionamiento de la información básica del negocio, contacto y perfil de la empresa en los principales sites, redes de negocio o directorios de empresas y profesionales. </p>
                    <a target="_blank" href="https://www.elonial.com/posicionamiento-seo"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>SEO Básico</h4>
                    <p>Análisis de palabras clave, SEO On-Page de 2 páginas o apartados, indexación y jerarquización del contenido.</p>
                    <a target="_blank" href="https://www.elonial.com/posicionamiento-seo"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
           </div>
        </div>
        <!-- Servicios!-->
     </div>
  </div>

  <!--Gestión Redes Sociales -->
  <div class="fancy-text-block-five pt-100 pb-100 md-pt-100 md-pb-100">
     <div class="container">
        <div class="row">
           <div class="col-lg-10">
              <div class="title-style-one mb-50 md-mb-30">
                 <h2 id="redessociales">02. Gestión de Redes Sociales</h2>
                 <p>Desde 150€-300€ (Mensuales)</p>
                 <p><b>Objetivo:</b> Promocionar a las empresas beneficiarias en redes sociales.</p>
                 <div class="pt-20">
                    <b>IMPORTE DE LA AYUDA</b>
                    <div  style="display:flex;flex-wrap: wrap;gap:20px;">
                       <ul>
                          <li> <u>SEGMENTO III</u> </li>
                          <li>2000€</li>
                          <li>0 - 3 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO II</u> </li>
                          <li>2500€</li>
                          <li>3 - 9 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO I</u> </li>
                          <li>2500€</li>
                          <li>10 - 50 Empleados</li>
                       </ul>
                    </div>
                 </div>
              </div>
              <!-- /.title-style-one -->
           </div>
        </div>
        <div class="wrapper mt-10">
           <!-- ¡Servicios-->
           <h3 class="uppercases" align="center">SOCIAL MEDIA</h3>
           <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Auditoría Social Media</h4>
                    <p>Análisis de los diferentes canales sociales para poder optimizar el rendimiento.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Accesibilidad</h4>
                    <p>El diseño debe cumplir con criterios de conformidad de nivel AA de las Pautas WCAG-2.1.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Social Media</h4>
                    <p>Configuración e integración de métodos de envío digital y físico de los productos comercializados por el beneficiario en la tienda online.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Formas de envío</h4>
                    <p>Configuración e integración de métodos de envío digital y físico de los productos comercializados por el beneficiario en la tienda online.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Autogestionable</h4>
                    <p>Se deberá proveer una plataforma de gestión de contenidos para el beneficiario, de manera que sea autónomo a la hora de modificar el contenido de sus páginas web, sin la necesidad de recurrir al soporte de la empresa proveedora.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Monitorización de redes sociales</h4>
                    <p>Monitorización y control periódico a través de métricas de referencia del impacto de las acciones, para cuantificar los resultados y comprobar si se están cumpliendo los objetivos marcados en la estrategia.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
           </div>
        </div>
        <!-- Servicios!-->
        <div class="wrapper mt-10">
           <!-- ¡Servicios-->
           <h3 class="uppercases" align="center">COMMUNITY MANAGEMENT</h3>
           <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Gestión de una red social</h4>
                    <p>Administración del perfil/usuario de la pyme en, al menos, una red social.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Publicación de posts semanales</h4>
                    <p>Publicación por parte del agente digitalizador de un mínimo de 4-8 entradas (posts) mensuales.</p>
                    <a target="_blank" href="https://www.elonial.com/redes-sociales"><i class="flaticon-right-arrow"></i></a>
                 </div>
                 <!-- /.block-style-four -->
              </div>
              <!-- /.block -->
           </div>
        </div>
        <!-- Servicios!-->
     </div>
  </div>
  
  <!--Gestión de Clientes -->
  <div class="fancy-text-block-five pt-100 pb-100 md-pt-100 md-pb-100 bg-color-blue">
     <div class="container">
        <div class="row">
           <div class="col-lg-10">
              <div class="title-style-one mb-50 md-mb-30">
                 <h2 id="crm">03. Gestión de Clientes</h2>
                 <p>Desde 79€ (Mensuales)</p>
                 <p><b>Objetivo:</b> Digitalización y/o automatización de procesos y flujos de trabajo.</p>
                 <div class="pt-20">
                    <b>IMPORTE DE LA AYUDA</b>
                    <div  style="display:flex;flex-wrap: wrap;gap:20px;">
                       <ul>
                          <li> <u>SEGMENTO III</u> </li>
                          <li>2000€</li>
                          <li>0 - 3 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO II</u> </li>
                          <li>2000€</li>
                          <li>3 - 9 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO I</u> </li>
                          <li>2000€</li>
                          <li>10 - 50 Empleados</li>
                       </ul>
                    </div>
                 </div>
              </div>
              <!-- /.title-style-one -->
           </div>
        </div>
        <div class="wrapper mt-10">
           <!-- ¡Servicios-->
           <h3 class="uppercases" align="center">CRM & INTRANET</h3>
           <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Gestión de clientes</h4>
                    <p>La solución almacena y permite la consulta de datos de cada cliente desde su alta como oportunidad de negocio y simulación de compra de productos o contratación de servicios.</p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Gestión de oportunidades</h4>
                    <p>La solución gestiona todas las oportunidades de negocio que comporten el envío al cliente potencial de ofertas y presupuestos. Además, la solución contempla el estado de cada oportunidad (en análisis, oferta presentada, negociación, ganadas, canceladas, etc.).</p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Gestión de leads</h4>
                    <p>La solución permite que se puedan dar de alta nuevos leads de forma manual o mediante una importación por archivo. Los datos asociados a los leads permiten su gestión comercial con el objetivo de convertirlos en clientes. La solución incluye la funcionalidad de parametrización de reglas de negocio para la asignación de leads según distintos criterios..</p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Acciones o tareas comerciales</h4>
                    <p>Se ofrece la posibilidad de crear acciones y tareas comerciales.</p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Reporting, planificación y seguimiento comercial</h4>
                    <p>Se ofrecen soluciones de seguimiento mediante indicadores (KPI), pipeline y otros, con distintos niveles de agregación de información en función del perfil del usuario de la solución.</p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Gestión documental</h4>
                    <p>Gestión centralizada de la documentación.</p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
           </div>
        </div>
     </div>
  </div>

   <!--Oficina virtual -->
   <div class="fancy-text-block-five pt-100 pb-100 md-pt-100 md-pb-100">
     <div class="container">
        <div class="row">
           <div class="col-lg-10">
              <div class="title-style-one mb-50 md-mb-30">
                 <h2 id="oficina">04.  Servicios y herramientas de Oficina Virtual</h2>
                 <p>Desde 79€ (Mensuales)</p>
                 <p><b>Objetivo:</b> Soluciones interactivas y funcionales para favorecer la colaboración más eficiente y efectiva.</p>
                 <div class="pt-20">
                    <b>IMPORTE DE LA AYUDA</b>
                    <div  style="display:flex;flex-wrap: wrap;gap:20px;">
                       <ul>
                          <li> <u>SEGMENTO III</u> </li>
                          <li>2000€</li>
                          <li>0 - 3 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO II</u> </li>
                          <li>2000€</li>
                          <li>3 - 9 Empleados</li>
                       </ul>
                       <ul>
                          <li> <u>SEGMENTO I</u> </li>
                          <li>2000€</li>
                          <li>10 - 50 Empleados</li>
                       </ul>
                    </div>
                 </div>
              </div>
              <!-- /.title-style-one -->
           </div>
        </div>
        <div class="wrapper mt-10">
           <!-- ¡Servicios-->
           <h3 class="uppercases" align="center">OFICINA VIRTUAL</h3>
           <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Colaboración en equipos de trabajo</h4>
                    <p> Definición del proceso de gestión colaborativa, compartición de recursos y conocimientos, implantación de las herramientas necesarias y resolución de problemas y soporte..</p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Almacenamiento y compartición de archivos</h4>
                    <p></p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
              <div class="col-lg-4 col-md-6" 
                 data-aos="fade-up" data-aos-duration="1200">
                 <div class="block-style-four">
                    <h4>Calendario y agenda</h4>
                    <p></p>
                    <a target="_blank" href="https://www.nectios.com/professional-network"><i class="flaticon-right-arrow"></i></a>
                 </div>
              </div>
              <!-- /.block -->
           </div>
        </div>
     </div>
  </div>
</template>
<script>
  export default {
    name: 'FancyTextBlockTwo',
   
  }
</script>