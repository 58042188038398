import {createRouter, createWebHistory} from 'vue-router'
import HomePageOne from '../pages/home';
import TermsCondition from '../pages/terms-condition';
import NotFound from '../pages/404';
import ProfessionalNetwork from '../pages/professional-network';
import EventsNectios from '../pages/events';
import LearningNectios from '../pages/courses';
import EconomicPromotion from '../pages/usecases/economic-promotion';
import OTRISNectios from '../pages/usecases/research-results-transfer-office';
import KitDigital from '../pages/kit-digital';
import BusinessCommunity from '../pages/usecases/business-community';
import SummitsNectios from '../pages/usecases/summits-conferences';
import Features from '../pages/features.vue';
import WebinarsNectios from '../pages/usecases/webinars';


const routes = [
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'Nectios | NotFound',
        },
    },
   
    {
        path: '/',
        name: 'HomePageOne',
        component: HomePageOne,
        meta: {
            title: 'Nectios | Home',
            ogImage: 'https://www.nectios.com/destacada.jpg',
            description: 'All-in-one platform for community growth.  Foster connections among professional agents and turn your event into a memorable experience.'
        },
    },
    {
        path: '/professional-network',
        name: 'Network',
        component: ProfessionalNetwork,
        meta: {
            title: 'Nectios | Professional Network',
            ogImage: 'https://www.nectios.com/destacada-professional.jpg',
            description: 'Foster connections among professional agents. Build a custom space for your professional network, engage your members and promote collaborative solutions.'
        },
    },
    {
        path: '/events',
        name: 'Events',
        component: EventsNectios,
        meta: {
            title: 'Nectios | Events',
            ogImage: 'https://www.nectios.com/destacada-events.jpg',
            description: 'Turn your event into a memorable experience. Organize, celebrate and manage your event, all in a single platform.'
        },
    },
    {
        path: '/courses',
        name: 'LearningNectios',
        component: LearningNectios,
        meta: {
            title: 'Nectios | Courses',
            ogImage: 'https://www.nectios.com/destacada-events.jpg',
            description: 'Turn your event into a memorable experience. Organize, celebrate and manage your event, all in a single platform.'
        },
    },
    {
        path: '/economic-promotion',
        name: 'EconomicPromotion',
        component: EconomicPromotion,
        meta: {
            title: 'Nectios | Economic Promotion',
        },
    },
    {
        path: '/business-community',
        name: 'BusinessCommunity',
        component: BusinessCommunity,
        meta: {
            title: 'Nectios | Business Community',
        },
    },
    {
        path: '/summits-conferences',
        name: 'SummitsNectios',
        component: SummitsNectios,
        meta: {
            title: 'Nectios | Summits & Conferences',
        },
    },

    {
        path: '/research-results-transfer-office',
        name: 'OTRISNectios',
        component: OTRISNectios,
        meta: {
            title: 'Nectios | Research Results Transfer Office',
        },
    },
    {
        path: '/webinars',
        name: 'WebinarsNectios',
        component: WebinarsNectios,
        meta: {
            title: 'Nectios | Webinars',
        },
    },
    {
        path: '/kit-digital',
        name: 'KitDigitalNectios',
        component: KitDigital,
        meta: {
            title: 'Nectios | Kit Digital',
            ogImage: 'https://www.nectios.com/destacada.jpg',
            description: 'All-in-one platform for community growth.  Foster connections among professional agents and turn your event into a memorable experience.'
        },
    },
    {
        path: '/features',
        name: 'FeaturesNectios',
        component: Features,
        meta: {
            title: 'Nectios | Features',
            ogImage: 'https://www.nectios.com/destacada.jpg',
            description: 'All-in-one platform for community growth.  Foster connections among professional agents and turn your event into a memorable experience.'
        },
    },
    {
        path: '/terms-condition',
        name: 'TermsCondition',
        component: TermsCondition,
        meta: {
            title: 'Nectios | Terms and Conditions',
        },
    },
   
    
   
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
