<template>
  <div class="main-page-wrapper">
    <Header  />
    <HeroKitDigital/>
    <KitWeb/>
    <CTAB2B/>
   <Contact id="contact"/>
    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/HeaderFour.vue";
import CTAB2B from "../common/CTA/B2B.vue";
import Footer from "../common/Footers/Footer.vue";
import Contact from "../common/Contact.vue";
import HeroKitDigital from "./HeroKitDigital.vue";
import KitWeb from "./KitWeb.vue";

export default {
  name: "KitDigitalNectios",
  components: {
    Header,
    CTAB2B,
    Footer,
    Contact,
    HeroKitDigital,
    KitWeb
},

};
</script>