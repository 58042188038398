<template>
  <Network/>
</template>

<script>
import Network from '../components/professional-network';

export default {
  name:'ProfessionalNetwork',
  components:{
    Network
  },

 
}
</script>