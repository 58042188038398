<template>
  <KitDigital/>
</template>

<script>
import KitDigital from '../components/KitDigital';

export default {
  name:'KitDigitalNectios',
  components:{
    KitDigital
  }
}
</script>