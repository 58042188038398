<template class="learning">
  <div class="fancy-feature-seven valuelearning">
    <div class="container">
      <div class="title-style-two text-center mb-50 md-mb-70">
        <p>{{ $t("courses-value.title") }}</p>
        <h2>{{ $t("courses-value.h2") }}</h2>
      </div>
      <!-- /.title-style-two -->

      <div class="block-wrapper">
        <div
          v-for="item in feature_data"
          :key="item.id"
          class="block-style-nine learning"
        >
          <div class="row align-items-center">
            <div
              :class="`col-lg-7 col-md-9 m-auto ${
                item.order_1 ? item.order_1 : ''
              }`"
              :data-aos="item.fade_1"
              data-aos-duration="1200"
            >
              <div class="illustration-holder">
                <img :src="item.img" alt="" />
              </div>
              <!-- /.illustration-holder -->
            </div>
            <div
              :class="`col-lg-5 ${item.order_2 ? item.order_2 : ''}`"
              :data-aos="item.fade_2"
              data-aos-duration="1200"
            >
              <div class="text-wrapper">
                <h6>{{ item.sm_title }}</h6>
                <h3 class="title">{{ item.title }}</h3>
                <p>{{ item.subtitle }}</p>
              </div>
              <!-- /.text-wrapper -->
            </div>
          </div>
        </div>
        <!-- /.block-style-nine -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueLearning",
  computed: {
    feature_data() {
      return [
        {
          id: 1,
          fade_1: "fade-left",
          fade_2: "fade-right",
          order_1: "order-lg-last",
          order_2: "order-lg-first",
          img: require(`@/assets/images/assets/courses01.png`),
          sm_title: this.$t("courses-value.s1_sm"),
          title: this.$t("courses-value.s1_title"),
          subtitle: this.$t("courses-value.s1_desc"),
        },
        {
          id: 2,
          fade_1: "fade-right",
          fade_2: "fade-left",
          img: require(`@/assets/images/assets/courses02.png`),
          sm_title: this.$t("courses-value.s2_sm"),
          title: this.$t("courses-value.s2_title"),
          subtitle: this.$t("courses-value.s2_desc"),
        },
        {
          id: 3,
          fade_1: "fade-left",
          fade_2: "fade-right",
          order_1: "order-lg-last",
          order_2: "order-lg-first",
          img: require(`@/assets/images/assets/courses03.png`),
          sm_title: this.$t("courses-value.s3_sm"),
          title: this.$t("courses-value.s3_title"),
          subtitle: this.$t("courses-value.s3_desc"),
        },
      ];
    },
  },
};
</script>
<style>
div.fancy-feature-seven.valuelearning
  > div
  > div.block-wrapper
  > div:nth-child(3)::before {
  display: none;
}
</style>
