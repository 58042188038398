<template>
  <ul class="mega-menu d-flex">
    <li v-for="item in homes_data" :key="item.id">
      <router-link :to="item.url" class="dropdown-item img-box">
        <img :src="item.img" alt="" />
        <span>{{ item.title }}</span>
      </router-link>
      <span>{{ item.description }}</span>
    </li>
    <!--<li>
      <div class="img-box">
        <img src="../../../assets/images/menu/home15.png" alt="" />
        <div class="hover d-flex align-items-center justify-content-center">
          <div class="font-rubik">Coming Soon</div>
        </div>
      </div>
      <span class="font-rubik">E-commerce</span>
    </li> -->
  </ul>
</template>

<script>
export default {
  name: "mega-menus",
  computed: {
    homes_data() {
      return [
        {
          id: 1,
          url: "/professional-network",
          img: require(`@/assets/images/menu/network.png`),
          title: this.$t("mega-menus.s1_title"),
          description: this.$t("mega-menus.s1_desc"),
        },
        {
          id: 2,
          url: "/events",
          img: require(`@/assets/images/menu/events.png`),
          title: this.$t("mega-menus.s2_title"),
          description: this.$t("mega-menus.s2_desc"),
        },
        {
          id: 3,
          url: "/courses",
          img: require(`@/assets/images/menu/courses.png`),
          title: this.$t("mega-menus.s3_title"),
          description: this.$t("mega-menus.s3_desc"),
        },
      ];
    },
  },
};
</script>
<style>
@media (min-width: 992px) {
  #collapsibleNavbar
    > div
    > ul.navbar-nav
    > li.nav-item.dropdown.position-static
    > div {
    max-width: 600px;
  }
  #mega-menu-holder .mega-menu li {
    width: 33%;
  }
  .dropdown-menu {
    background-color: transparent !important;
  }
  #mega-menu-holder .mega-menu {
    position: relative;
    left: 20vw;
    background-color: white;
  }
}
#collapsibleNavbar
  > div
  > ul.navbar-nav
  > li.nav-item.dropdown.position-static
  > div
  > ul
  > li
  > span {
  font-size: smaller;
  color: gray;
}
</style>
