<template>
  <div class="fancy-feature-seven">
    <div class="container">
      <div class="title-style-two text-center mb-50 md-mb-70">
        <p>Value for your entity</p>
        <h2>A perfect space for B2B <span>networking<img src="../../../assets/images/shape/line-shape-5.svg" alt=""></span>
        </h2>
      </div> <!-- /.title-style-two -->

      <div class="block-wrapper">

        <div v-for="item in feature_data" :key="item.id" class="block-style-nine event">
          <div class="row align-items-center">
            <div :class="`col-lg-7 col-md-9 m-auto ${item.order_1 ? item.order_1 : ''}`" 
            :data-aos="item.fade_1" data-aos-duration="1200">
              <div class="illustration-holder">
                <img :src="item.img" alt="">
              </div> <!-- /.illustration-holder -->
            </div>
            <div :class="`col-lg-5 ${item.order_2 ? item.order_2 : ''}`" 
            :data-aos="item.fade_2" data-aos-duration="1200">
              <div class="text-wrapper">
                <h6>{{item.sm_title}}</h6>
                <h3 class="title">{{item.title}}</h3>
                <p >{{item.subtitle}}</p>
              </div> <!-- /.text-wrapper -->
            </div>
          </div>
        </div> <!-- /.block-style-nine -->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValueEvent',
  data () {
    return {
      feature_data : [
      
        {
          id:1,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/economic01.png`),
          sm_title:'CONNECT',
          title:'📱Build a professional directory ',
          subtitle:'Promote new business opportunities among your members where clients and providers can find each other.',
        },
        {
          id:2,
          fade_1:'fade-right',
          fade_2:'fade-left',
          img:require(`../../../assets/images/assets/economic02.png`),
          sm_title:'ENGAGE',
          title:'📢 Host networking events',
          subtitle:'Create, organize and manage any kind of events (onsite, virtual or hybrid) with added value to bring together the professional agents of your area.',
        },
        {
          id:3,
          fade_1:'fade-left',
          fade_2:'fade-right',
          order_1:'order-lg-last',
          order_2:'order-lg-first',
          img:require(`../../../assets/images/assets/economic03.png`),
          sm_title:'SHARE',
          title:'📂 Feed a virtual repository',
          subtitle:'Create an accessible knowledge data base to share with your members any resource.'
        },
        {
          id:4,
          fade_1:'fade-right',
          fade_2:'fade-left',
          img:require(`@/assets/images/assets/economic04.png`),
          sm_title:'PERFECT HIRINGS',
          title:'👩‍💼 Increase employability',
          subtitle:'Build a job portal where the agents of your area can find new work opportunities.'
        },
        
      ]
    }
  }
}
</script>
<style>


</style>