<template>
  <div class="fancy-feature-one mt-100 md-mt-0 md-pb-0">
    <div class="text-center">
      <div class="feature-img-area mb-200">
        <img
          src="../../assets/images/assets/feature-01.png"
          style="max-width: 1080px"
          alt="nectios platform"
        />
        <img
          src="../../assets/images/assets/feature-02.png"
          alt="nectios platform"
          class="cs-screen screen-one"
        />
        <!--

        <img src="../../assets/images/assets/feature-img-01-02.png" alt="" class="cs-screen screen-two"
          data-aos="fade-left" data-aos-duration="1200" />
        <img src="../../assets/images/assets/feature-img-01-03.png" alt="" class="cs-screen screen-three"
          data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100" />
        <img src="../../assets/images/assets/feature-img-01-04.png" alt="" class="cs-screen screen-four"
          data-aos="fade-right" data-aos-duration="1200" data-aos-delay="150" />
        <img src="../../assets/images/assets/feature-img-01-05.png" alt="" class="cs-screen screen-five"
          data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200" />
      --></div>
      <!-- /.feature-img-area -->
    </div>
    <!-- /.text-center 

    <div class="block-style-one">
      <div class="container">
        <div class="d-lg-flex align-items-center justify-content-between inner-container">
          <div v-for="item in featureData" :key="item.id" class="block-meta-data text-center">
            <div class="line-dot-container">
              <div class="icon-box">
                <img :src="item.icon" alt="" />
              </div>
             /.icon-box 
              <p>{{ item.title }}</p>
            </div>
            <div :class="item.textAlign ? item.textAlign : 'hover-content'">
              <span>{{ item.desc }}</span>
            </div>
          </div>
        /.block-meta-data 

        </div>
      </div>
     /.container 
    </div>
   /.block-style-one -->
  </div>
</template>

<script>
export default {
  name: "featureOne",
  data() {
    return {
      featureData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/01.svg`),
          title: "Choose features",
          desc: "Go to our App Store, choose the features you want to use, and install and display them just one click. No code involved!",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/02.svg`),
          title: "Edit your layout",
          desc: "Nectios is a modular platform, so you can use our drag and drop technology to easily customize your layout according to your needs.",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/03.svg`),
          title: "Start creating",
          desc: "Use our interactive tools to engage your community: celebrate meetings, publish job offers, share collaborative projects, and much more!",
          textAlign: "hover-content text-center",
        },
      ],
    };
  },
};
</script>
