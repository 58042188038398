<template>
  <div class="main-page-wrapper">
    <Header />
    <HeroNetwork />
    <CarouselLogos />
    <ValueNetwork />
    <UseCasesNetwork />
    <StoriesNetwork />
    <Community />
    <Pricing id="pricing" />
    <Contact id="contact" />

    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/HeaderFour.vue";
import HeroNetwork from "./HeroNetwork.vue";
import ValueNetwork from "./ValueNetwork.vue";
import Community from "../common/CTA/Community.vue";
import UseCasesNetwork from "./UseCasesNetwork.vue";
import StoriesNetwork from "./StoriesNetwork.vue";
import Footer from "../common/Footers/Footer.vue";
import Contact from "../common/Contact.vue";
import CarouselLogos from "../common/Logos.vue";
import Pricing from "../common/Pricing.vue";

export default {
  name: "ProfessionalNetwork",
  components: {
    Header,
    HeroNetwork,
    ValueNetwork,
    UseCasesNetwork,
    Community,
    StoriesNetwork,
    Contact,
    Footer,
    CarouselLogos,
    Pricing,
  },
};
</script>
