<template>
  <Features/>
</template>

<script>
import Features from '../components/features';

export default {
  name:'FeaturesNectios',
  components:{Features}
}
</script>