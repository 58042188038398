<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <div class="fancy-hero-four bg-doc space-fix">
      <div class="bg-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
              <h2>{{ $t("contact.h2") }}</h2>
            </div>
          </div>
        </div>
      </div>
      <!-- /.bg-wrapper -->
    </div>

    <div class="contact-style-two">
      <div class="container">
        <div class="contact-info-wrapper">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 d-lg-flex">
              <div class="address-info">
                <div class="icon d-flex align-items-end">
                  <img
                    src="../../assets/images/icon/solution1.svg"
                    alt="location"
                  />
                </div>
                <div class="title">{{ $t("contact.location") }}</div>
                <p v-html="$t('contact.location_info')"></p>
              </div>
              <!-- /.address-info -->
            </div>
            <div class="col-lg-4 col-sm-6 d-lg-flex">
              <div class="address-info">
                <div class="icon d-flex align-items-end">
                  <img
                    src="../../assets/images/icon/solution6.svg"
                    alt="contact"
                  />
                </div>
                <div class="title">{{ $t("contact.contact") }}</div>
                <p v-html="$t('contact.contact_info')"></p>
              </div>
              <!-- /.address-info -->
            </div>
            <div class="col-lg-4 col-sm-6 d-lg-flex">
              <div class="address-info">
                <div class="icon d-flex align-items-end">
                  <img
                    src="../../assets/images/icon/46.svg"
                    alt="social-media"
                  />
                </div>
                <div class="title">{{ $t("contact.socialmedia") }}</div>
                <p v-html="$t('contact.socialmedia_info')"></p>
                <ul class="d-flex justify-content-center">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/nectios"
                      ><i class="fa fa-linkedin" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a target="_blank" href="https://twitter.com/joinNectios"
                      ><i class="fa fa-twitter" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/nectios/"
                      ><i class="fa fa-instagram" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <!-- /.address-info -->
            </div>
          </div>
          <img
            src="../../assets/images/shape/64.svg"
            alt=""
            class="shapes shape-one"
          />
          <img
            src="../../assets/images/shape/65.svg"
            alt=""
            class="shapes shape-two"
          />
        </div>
        <!-- /.contact-info-wrapper -->

        <div class="contact-us-light pb-10 md-pt-50 md-pb-50">
          <div class="container">
            <div class="form-style-light">
              <form
                @submit="onSubmit"
                id="contact-form"
                data-bs-toggle="validator"
              >
                <div class="messages"></div>
                <div class="row controls">
                  <div class="col-md-6">
                    <div class="input-group-meta form-group mb-35">
                      <label>{{ $t("contact.first_name") }}</label>
                      <input
                        type="text"
                        placeholder="Michel"
                        name="Fname"
                        required="required"
                        data-error="Name is required."
                        v-model="firstName"
                      />
                      <span class="placeholder_icon valid-sign">
                        <img src="../../assets/images/icon/18.svg" alt="" />
                      </span>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group-meta form-group mb-35">
                      <label>{{ $t("contact.last_name") }}</label>
                      <input
                        type="text"
                        placeholder="Simon"
                        name="Lname"
                        required="required"
                        data-error="Name is required."
                        v-model="lastName"
                      />
                      <span class="placeholder_icon valid-sign">
                        <img src="../../assets/images/icon/18.svg" alt="" />
                      </span>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group-meta form-group mb-35">
                      <label>{{ $t("contact.your_email") }}</label>
                      <input
                        type="email"
                        placeholder="example@gmail.com"
                        name="email"
                        required="required"
                        data-error="Valid email is required."
                        v-model="email"
                      />
                      <span class="placeholder_icon valid-sign">
                        <img src="../../assets/images/icon/18.svg" alt="" />
                      </span>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-group-meta form-group lg mb-35">
                      <label>{{ $t("contact.your_message") }}</label>
                      <textarea
                        placeholder="Write your message here"
                        name="message"
                        data-error="Please,leave us a message."
                        v-model="message"
                      ></textarea>
                      <div class="help-block with-errors"></div>
                    </div>
                  </div>
                  <div class="col-12">
                    <input
                      type="submit"
                      class="primary-button"
                      value="Send Message"
                    />
                  </div>
                </div>
              </form>
            </div>
            <!-- /.form-style-light -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDocMain",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    };
  },
  methods: {
    async onSubmit() {
      try {
        await fetch("https://api.tendios.com/v1/landing/contactFormNectios", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            message: this.message,
          }),
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
