import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueEasyLightbox from "vue-easy-lightbox";
import router from "./routes";
import "bootstrap";
import "./index.css";
import App from "./App.vue";

// Import translations
import en from "./locales/en.json";
import es from "./locales/es.json";

const storedLanguage = localStorage.getItem("language") || "es";

const i18n = createI18n({
  locale: storedLanguage, // set locale
  fallbackLocale: "en", // set fallback locale
  messages: { en, es },
  // If you need to specify other options, you can set other options
  // ...
});

let app = createApp(App);

app.use(router);
app.use(VueEasyLightbox);
app.use(i18n);

app.mount("#app");
