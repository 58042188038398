<template>
  <Events/>
</template>

<script>
import Events from '../components/events';

export default {
  name:'EventsNectios',
  components:{
    Events
  },
   
}
</script>