<template>
  <ul class="navbar-nav">
    <li class="nav-item dropdown position-static">
      <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
        {{ $t("navmenu.solutions") }}
      </a>
      <div class="dropdown-menu">
        <!-- megaMenus start -->
        <megaMenus />
        <!-- megaMenus end -->
      </div>
      <!-- /.dropdown-menu -->
    </li>
    <li class="nav-item">
      <a href="features" class="nav-link"> {{ $t("navmenu.features") }} </a>
    </li>
    <li class="nav-item mega-dropdown-md">
      <a href="#pricing" class="nav-link"> {{ $t("navmenu.pricing") }} </a>
    </li>
    <li class="nav-item">
      <a href="#contact" class="nav-link"> {{ $t("navmenu.contact") }} </a>
    </li>
  </ul>
</template>
<script>
import megaMenus from "./mega-menus.vue";

export default {
  name: "NavMenu",
  components: {
    megaMenus,
  },
  data() {
    return {
      portfolio_data: [
        {
          url: "/portfolio-v1",
          title: "Classic Style",
        },
        {
          url: "/portfolio-v2",
          title: "Grid 2 Columns",
        },
        {
          url: "/portfolio-v3",
          title: "Grid 3 Columns",
        },
        {
          url: "/portfolio-v4",
          title: "Gallery Slider",
        },
        {
          url: "/portfolio-v5",
          title: "Grid Single",
        },
        {
          url: "/portfolio-details",
          title: "Classic Details",
        },
      ],
      pages_menu_data: [
        {
          title: "About Us",
          title_2: "Team",
          dropdown_list: [
            {
              url: "/about-us-cs",
              title: "Customer Support",
            },
            {
              url: "/about-us-event",
              title: "Event Organizer",
            },
            {
              url: "/about-us-management",
              title: "Project Management",
            },
            {
              url: "/about-us-doc",
              title: "Documentation",
            },
          ],
          dropdown_list_2: [
            {
              url: "/team-v1",
              title: "Team Version 01",
            },
            {
              url: "/team-v2",
              title: "Team Version 02",
            },
            {
              url: "/team-details",
              title: "Team Details",
            },
          ],
        },

        {
          title: "Pricing",
          title_2: "Contact Us",
          dropdown_list: [
            {
              url: "/pricing-cs",
              title: "Customer Support",
            },
            {
              url: "/pricing-event",
              title: "Event Organizer",
            },
            {
              url: "/pricing-management",
              title: "Project Management",
            },
          ],
          dropdown_list_2: [
            {
              url: "/contact-us-cs",
              title: "Customer Support",
            },
            {
              url: "/contact-us-event",
              title: "Event Organizer",
            },
            {
              url: "/contact-us-management",
              title: "Project Management",
            },
            {
              url: "/contact-us-doc",
              title: "Documentation",
            },
          ],
        },

        {
          title: "Services",
          title_2: "Feature",
          dropdown_list: [
            {
              url: "/services-v1",
              title: "Service Version 01",
            },
            {
              url: "/services-v2",
              title: "Service Version 02",
            },
            {
              url: "/services-v3",
              title: "Service Version 03",
            },
            {
              url: "/services-v4",
              title: "Service Version 04",
            },
            {
              url: "/service-details",
              title: "Service Details",
            },
          ],
          dropdown_list_2: [
            {
              url: "/solution-management",
              title: "Our Solution",
            },
            {
              url: "/product-cs",
              title: "Product Feature",
            },
            {
              url: "/feature-cs",
              title: "Our Features",
            },
          ],
        },

        {
          title: "Others",
          dropdown_list: [
            {
              url: "/faqs",
              title: "FAQ",
            },
            {
              url: "/faq-details",
              title: "Faq Details",
            },
            {
              url: "/terms-condition",
              title: "Terms & Condition",
            },
            {
              url: "/login",
              title: "Login",
            },
            {
              url: "/register",
              title: "Signup",
            },
            {
              url: "/not-found",
              title: "404 Error",
            },
          ],
        },
      ],
      blog_menu_data: [
        {
          url: "/blog-v1",
          title: "Blog Version 01",
        },
        {
          url: "/blog-v2",
          title: "Blog Version 02",
        },
        {
          url: "/blog-v3",
          title: "Blog Version 03",
        },
        {
          url: "/blog-v4",
          title: "Blog Version 04",
        },
        {
          url: "/blog-details",
          title: "Blog Details",
        },
      ],
      doc_menu_data: [
        {
          url: "/doc-full-width",
          title: "Full Width",
        },
        {
          url: "/doc-full-width-banner",
          title: "Full Width Banner",
        },
        {
          url: "/doc-box",
          title: "Doc Box",
        },
        {
          url: "/doc-box-banner",
          title: "with Banner",
        },
        {
          url: "/changelog",
          title: 'Changelog <code class="text-danger">**</code>',
        },
      ],
    };
  },
};
</script>
