<template>
  <div class="hero-banner-eleven lg-container mb-110 md-mb-50">
     <div class="container">
        <div class="row">
           <div class="col-xl-7 col-lg-6" style="padding-left:5%">
              <div class="hero-text-wrapper">
               <p>SUMMITS & CONFERENCES</p>
                 <h1>
                  Increase <br><span>attendance rate
                    <img
                       src="../../../assets/images/shape/line-shape-12.svg"
                       alt=""
                       class="cs-screen"
                       style="z-index:-1"
                       />
                    </span> by 15%
                 </h1>
                 <p class="hero-sub-heading">Build an engaging virtual space and attract the perfect audience for your event.</p>
                 <button class="primary-button" style="margin-left: 0 !important;"> <a target="_blank" href="https://calendly.com/xavier-creus/30min">Request Demo</a> </button>
                <!-- <p class="mb-10 mt-10" style="font-size:14px;">Need help? <a style="text-decoration: underline;color: var(--blue-light)" target="_blank"  href="https://calendly.com/xavier-creus/30min">Talk to an expert</a></p>
                 
                    <form @submit.prevent="onSubmit">
                    <input type="email" placeholder="ihidago@nectios.com" />
                    <button>Request demo</button>
                    </form>
                    -->
                 <a target="_blank" class="term-text" href="https://www.capterra.es/software/1030228/nectios">
                 <img  src="../../../assets/images/assets/Capterra.png" alt="Capterra">
                 </a>
              </div>
              <!-- /.hero-text-wrapper -->
           </div>
           <!-- End .col -->
           <div class="illustration-container">
              <img src="../../../assets/images/assets/herosummits.png" alt="Nectios-community" />
           </div>
        </div>
        <!-- End .row -->
     </div>
     <!-- End .container -->
     <!-- /.partner-slider-two -->
  </div>
</template>
<script>
  export default {
    name: "HeroSummits",
   
   
    methods: {
      onSubmit() {
        console.log("Submitted");
      },
    },
  };
</script>