<template>
  <Summits/>
</template>

<script>
import Summits from '@/components/usecases/summits';

export default {
  name:'SummitsNectios',
  components:{
    Summits
  },
   
}
</script>