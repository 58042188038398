<template>
  <div class="main-page-wrapper overflow-hidden">
    <Header />
    <TermsConditionArea />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/HeaderFour.vue";
import TermsConditionArea from "./terms-condition-area.vue";
import Contact from "../common/Contact.vue";
import Footer from "../common/Footers/Footer.vue";

export default {
  name: "TermsConditionMain",
  components: { Header, TermsConditionArea, Contact, Footer },
};
</script>
