<template>
  <div class="main-page-wrapper">
    <Header />
    <HeroArea />
    <Logos />
    <FeatureOne />
    <Value />
    <Steps />
    <Solutions id="solutions" />
    <CTACommunity />
    <Pricing id="pricing" />
    <Contact id="contact" />
    <Footer />
  </div>
</template>

<script>
import Header from "../common/Headers/HeaderFour.vue";
import HeroArea from "./HeroBanner.vue";
import FeatureOne from "./FeatureOne.vue";
import Steps from "./Steps.vue";
import Value from "./Value.vue";
import CTACommunity from "../common/CTA/Community.vue";
import Solutions from "./Solutions.vue";
import Pricing from "../common/Pricing.vue";
import Footer from "../common/Footers/Footer.vue";
import Contact from "../common/Contact.vue";
import Logos from "../common/Logos.vue";

export default {
  name: "HomeMain",
  components: {
    Header,
    HeroArea,
    Steps,
    FeatureOne,
    Value,
    Solutions,
    CTACommunity,
    Pricing,
    Contact,
    Footer,
    Logos,
  },
};
</script>

<style>
.block-meta {
  transition: transform 0.3s ease;
}
.block-meta:hover {
  transform: scale(1.1);
}
</style>
