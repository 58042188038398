<template>
  <BusinessCommunity/>
</template>

<script>
import BusinessCommunity from '@/components/usecases/business-community';

export default {
  name:'UseCaseBusinessCommunity',
  components:{
    BusinessCommunity
  },
}
</script>