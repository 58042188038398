<template>
  <EconomicPromotion/>
</template>

<script>
import EconomicPromotion from '@/components/usecases/economic-promotion';

export default {
  name:'UseCaseEconomicPromotion',
  components:{
    EconomicPromotion
  },
   
}
</script>