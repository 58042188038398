<template>
   <div class="fancy-short-banner-eight pt-100 md-pt-80">
      <div class="container">
         <div class="row" style="align-items:center;">
            <div
               class="col-xl-8 col-lg-11 m-auto"
               data-aos="fade-up"
               data-aos-duration="1200"
               >
               <div class="title-style-two ">
                  <h2> Build your community today </h2>
               </div>
               <div class=" mt-30">
                  <p>Unify all the tools you need on a daily basis and keep your associates engaged with your activity with a single modular platform.</p>
               </div>
               <!-- /.title-style-six -->
            </div>
            <div class="download-btn mt-20" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
               <button> 
               <a target="_blank" href="https://calendly.com/xavier-creus/30min">Request demo</a>
               </button>
            </div>
         </div>
      </div>
      <!-- /.container -->
      <img
         src="../../../assets/images/shape/171.svg"
         alt=""
         class="shapes shape-one"
         />
      <img
         src="../../../assets/images/shape/172.svg"
         alt=""
         class="shapes shape-two"
         />
   </div>
</template>
<script>
   export default {
     name: "ShortBanner",
   };
</script>