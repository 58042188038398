<template>
  <WebinarsNectios/>
</template>

<script>
import WebinarsNectios from '@/components/usecases/webinars';

export default {
  name:'UseCaseWebinars',
  components:{
    WebinarsNectios
  },
   
}
</script>