<template>
  <div class="feature-blog-two pt-150 pb-170 md-pt-100 md-pb-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-11 m-auto">
          <div class="title-style-two text-center mb-50 md-mb-20">
            <p>{{ $t("stories.title") }}</p>
            <h2>{{ $t("stories.h2") }}</h2>
          </div>
          <!-- /.title-style-one -->
        </div>
      </div>

      <div class="row justify-content-center">
        <div 
          class="col-lg-6 col-md-6"
          data-aos="fade-up"
        >
        <a href="https://recursos.tendios.com">
          <div class="post-meta">
            <img src="https://www.tendios.com/assets/landing.9f2b33bf.png" alt="tendios success story" class="image-meta" />
            <h3>
              <p class="title">Tendios  </p>
            </h3>
          </div>
        </a>
          
        </div>
        <div
          v-for="blog in blogs.slice(6, 9)"
          :key="blog.id"
          class="col-lg-6 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          :data-aos-delay="blog.delay"
        >
          <div class="post-meta">
            <img :src="blog.img" alt="" class="image-meta" />
            <h3>
              <p class="title">{{ blog.title }} ></p>
            </h3>
            <!--<h3> <router-link :to="`/blog-details/${blog.id}`" class="title">{{ blog.title }}</router-link> </h3>-->
            <!-- <router-link :to="`/blog-details/${blog.id}`" class="read-more d-flex justify-content-between align-items-center">
              <span>{{ blog.tag }}</span>
              <i class="flaticon-right-arrow"></i>
            </router-link>-->
          </div>
          <!-- /.post-meta -->
        </div>

       
      </div>
    </div>
  </div>
</template>

<script>
import blogMixin from "@/mixin/blog-mixin";

export default {
  name: "StoriesNetwork",
  mixins: [blogMixin],
};
</script>
