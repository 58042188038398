<template>
  <OTRIS/>
</template>

<script>
import OTRIS from '@/components/usecases/otris';

export default {
  name:'OTRISNectios',
  components:{
    OTRIS
  },
   
}
</script>