<template>
  <footer class="theme-footer-five mt-130 md-mt-100">
    <div class="inner-container">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-4">
            <div class="logo">
              <router-link to="/"
                ><img
                  src="../../../assets/images/logo/nectios-logo.svg"
                  alt="logo nectios"
              /></router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="title">{{ $t("footer.find_us") }}</div>
            <ul class="d-flex justify-content-center social-icon">
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/nectios"
                  ><i class="fa fa-linkedin" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="https://twitter.com/joinNectios"
                  ><i class="fa fa-twitter" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/nectios/"
                  ><i class="fa fa-instagram" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>
          <div class="col-lg-4">
            <div class="title">{{ $t("footer.help") }}</div>
            <div class="text-center">
              <a href="#" class="email">hello@nectios.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.inner-container -->
    <div class="d-flex flex-wrap justify-content-between">
      <p class="copyright">
        {{ $t("footer.developed") }}
        <a
          style="color: var(--blue-light)"
          target="_blank"
          href="https://elonial.com/?utm_source=nectios&utm_medium=client_website"
          >Elonial</a
        >.
      </p>
      <a href="/terms-condition" class="copyright">{{
        $t("footer.privacy")
      }}</a>

      <a href="/terms-condition" class="copyright">{{ $t("footer.terms") }}</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterFive",
};
</script>
